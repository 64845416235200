<template>
  <div>
    <template v-if="formartedItems.length === 0 && detailProducts.length === 0">

      <!-- #region::Alert when no circuits are available -->
      <b-alert
        v-if="!editionModeState"
        variant="warning"
        class="my-2"
        show
      >
        <div class="alert-body">
          <span>No se agregaron circuitos a la cotización.</span>
        </div>
      </b-alert>
      <!-- #endregion::Alert when no circuits are available -->

    </template>

    <template v-else-if="formartedItems.length > 0">
      <b-form-row>

        <!-- #region::Title -->
        <b-col md="12">
          <h3>Circuitos</h3>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Circuit list -->
        <b-col>
          <b-card
            class="mt-1 border border-dark shadow-none"
            no-body
          >
            <b-card-text>

              <!-- #region::Circuits list -->
              <b-table
                :items="formartedItems"
                :fields="columns"
                responsive="sm"
                class="my-0"
              >

                <!-- #region::A virtual column for checbox -->
                <template #cell(selectCircuit)="{item}">
                  <b-form-checkbox
                    v-model="item.selected"
                    @change="onCircuitChecked(item)"
                  />
                </template>
                <!-- #endregion::A virtual column for checbox -->

                <!-- #region::A virtual column for name -->
                <template #cell(IdCircuit)="{ item }">
                  <div class="d-flex">
                    <span class="text-nowrap">{{ item.IdCircuit }}</span>
                    <div
                      v-if="item.Delivered === 'Si'"
                      class="sm-banner-alert alert-cyan"
                      role="alert"
                    >
                      Entrega
                    </div>
                    <div
                      v-else-if="item.Delivered === 'Entregado'"
                      class="sm-banner-alert alert-cyan"
                      role="alert"
                    >
                      Entregado
                    </div>
                    <div
                      v-if="showRefundTag && item.Devolution === 'Si'"
                      class="sm-banner-alert alert-marine"
                      role="alert"
                    >
                      En devolución
                    </div>
                  </div>
                </template>
                <!-- #endregion::A virtual column for name -->

                <!-- #region::A virtual column for name -->
                <template #cell(Name)="{ item }">
                  <span class="text-nowrap">{{ item.Name }}</span>
                </template>
                <!-- #endregion::A virtual column for name -->

                <!-- #region::A virtual column for prices -->
                <template #cell(prices)="{ item }">
                  <span class="text-nowrap">{{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}</span>
                </template>
                <!-- #endregion::A virtual column for prices -->

                <!-- #region::A virtual column for offer price -->
                <template #cell(OfferPrice)="{ item }">
                  <div
                    v-if="item.OfferPrice"
                    class="d-flex"
                  >
                    <b-form-checkbox
                      v-if="item.ActiveOfferPrice"
                      style="margin-left: -25px !important;"
                      :checked="item.ActiveOfferPrice"
                      :disabled="true"
                    />
                    <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
                  </div>
                </template>
                <!-- #endregion::A virtual column for offer price -->

                <!-- #region::A virtual column for total -->
                <template #cell(total)="{ item }">
                  {{ totalUnitPrices(item) | currency }}
                </template>
                <!-- #endregion::A virtual column for total -->

                <!-- #region::A virtual column for location -->
                <template #cell(Location)="{ item }">
                  <span class="text-nowrap">{{ item.Location }}</span>
                </template>
                <!-- #endregion::A virtual column for location -->

                <!-- #region::A virtual column for actions -->
                <template #cell(actions)="{ item }">
                  <b-button
                    v-b-tooltip.hover.top="'Eliminar'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="onDeleteCicuit(item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </template>
                <!-- #endregion::A virtual column for actions -->

                <!-- #region::A virtual column for toggle button -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  #cell(details)="{ detailsShowing, item }"
                >
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="toggleDetails(item)"
                  >
                    <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                  </b-button>
                </template>
                <!-- #endregion::A virtual column for toggle button -->

                <!-- #region::Circuit details -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  v-slot:row-details="{ item }"
                >
                  <b-card
                    no-body
                    class="mt-1"
                  >
                    <RefundProductCollapsableItem
                      v-for="product in item.products"
                      :key="product.IdQuotesCircuitsProduct"
                      :product="product"
                      :show-edit-button="false"
                      :show-delete-button="false"
                      :is-visible="false"
                      :disabled-form-components="!editionModeState"
                      :show-pieces-list-actions-buttons="false"
                      :set-pieces-query="true"
                    />
                  </b-card>
                </template>
                <!-- #endregion::Circuit details -->

              </b-table>
              <!-- #endregion::Circuits list -->

            </b-card-text>
          </b-card>
        </b-col>
        <!-- #endregion::Circuit list -->

      </b-form-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BFormRow, BCard, BCardText, BTable, BButton, BAlert, VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import buildProduct from '@/helpers/CircuitProductConverter'

export default {
  name: 'OrderCircuitDetails',
  components: {
    BCol,
    BCard,
    BTable,
    BAlert,
    BButton,
    BFormRow,
    BCardText,
    BFormCheckbox,
    RefundProductCollapsableItem: () => import('@/modules/store/refunds/components/cards/RefundProductCollapsableItem.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    showRefundTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      circuits: [],
      currentItems: [],
      columns: [
        {
          key: 'selectCircuit',
          label: '',
          thClass: this.selectable ? 'bg-light' : 'd-none',
          tdClass: this.selectable ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light text-nowrap',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: !this.$ability.can('received', 'Devolution') ? 'bg-light' : 'd-none',
          tdClass: !this.$ability.can('received', 'Devolution') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'total',
          label: 'Total',
          thClass: !this.$ability.can('received', 'Devolution') ? 'bg-light' : 'd-none',
          tdClass: !this.$ability.can('received', 'Devolution') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light text-nowrap',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'd-none',
          tdClass: 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: 'bg-light',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getRefund: 'refunds/getRefund',
      getDetailProducts: 'orders/getDetailProducts',
      getDetailCircuits: 'orders/getDetailCircuits',
      getEditionModeState: 'orders/getEditionModeState',
      getDetailCircuitsWithoutDeliveredState: 'orders/getDetailCircuitsWithoutDeliveredState',
      getDetailProductsWithoutDeliveredPieces: 'orders/getDetailProductsWithoutDeliveredPieces',
    }),
    refund: {
      get() { return this.getRefund },
    },
    detailProducts: {
      get() { return this.getDetailProducts },
    },
    detailCircuits: {
      get() { return this.getDetailCircuits },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    detailProductsWithoutDeliveredPieces: {
      get() { return this.getDetailProductsWithoutDeliveredPieces },
    },
    formartedItems() {
      if (this.detailCircuits.length === 0) return []
      return this.detailCircuits.map(item => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        this.$set(item, 'total', 0)
        this.offRow(item)
        return item
      })
    },
  },
  watch: {
    editionModeState(state) {
      if (state) {
        this.$set(this.columns[7], 'thClass', 'bg-light')
        this.$set(this.columns[7], 'tdClass', '')
      } else {
        this.$set(this.columns[7], 'thClass', 'd-none')
        this.$set(this.columns[7], 'tdClass', 'd-none')
      }
    },
    order() {
      this.buildCircuitsList()
    },
  },
  created() {
    this.buildCircuitsList()
  },
  methods: {
    ...mapActions({
      addDetailCircuit: 'orders/addDetailCircuit',
      deleteOrderCircuit: 'orders/deleteOrderCircuit',
      updateDetailCircuit: 'orders/updateDetailCircuit',
      addDetailProductPieces: 'orders/addDetailProductPieces',
      addPartialDeliveryProvitionalCircuit: 'orders/addPartialDeliveryProvitionalCircuit',
      deletePartialDeliveryProvitionalCircuit: 'orders/deletePartialDeliveryProvitionalCircuit',
    }),
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        total: '', selectCircuit: '', IdCircuit: '', Name: '', prices: '', Qty: '', Location: '', actions: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        total: 'primary', selectCircuit: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', actions: 'primary', details: 'primary',
      })
    },

    /**
    * Precio total del circuito
    *
    * @summary Calcula el precio total del circuito a partir de los productos y piezas disponibles.
    * Si el circuito tiene precio de oferta activo se retorna el precio de oferta
    * @param {Object} circuit - Elemento que representa un circuito de la lista
    * @return {Integer} Total de los productos y piezas del circuito
    */
    totalUnitPrices(circuit) {
      let total = 0

      if (circuit.OfferPrice && circuit.ActiveOfferPrice) {
        return circuit.OfferPrice
      }

      circuit.products.forEach(product => {
        total += product.UnitPrice * product.pieces.length
      })

      const baseCircuit = circuit
      baseCircuit.total = total

      return total
    },

    /**
     * Evento de selección de circuito
     *
     * @summary Evento que se ejecuta cuando se selecciona un circuito de la lista. Valida
     * que el circuito no se encuentre en una devolución y que no se encuentre en estado de entregado
     * @param {Object} circuit - Elemento que representa un circuito de la lista
     */
    onCircuitChecked(circuit) {
      if (circuit.Devolution === 'Si') {
        const baseCircuit = circuit
        baseCircuit.selected = false

        this.showToast(
          'Error de validación',
          'El circuito no puede ser deseleccionado ya que se encuentra en una devolución.',
          'warning',
        )

        return
      }

      const selectedCircuit = { ...circuit }
      selectedCircuit.selected = !selectedCircuit.selected
      if (!selectedCircuit.selected) {
        this.addPartialDeliveryProvitionalCircuit(selectedCircuit)
      } else {
        this.deletePartialDeliveryProvitionalCircuit(selectedCircuit)
      }
    },
    onDeleteCicuit(circuit) {
      if (circuit.Delivered === 'Entregado') {
        this.showToast(
          'Error de validación',
          'El circuito no puede ser eliminado ya que ha sido entregado.',
          'warning',
        )

        return
      }

      this.deleteOrderCircuit(circuit)
    },

    /**
    * Producto del circuito
    *
    * @summary Crea un producto que se adapta al componente del circuito. La informacion es proporcionada
    * por el servidor y es tratada para mostrarse de manera correcta
    * @param {object} circuitProduct - Producto del circuito proporcionado por el servidor
    * @return {object} producto que se muestra en el detalle de los circuitos
    */
    makeProduct(circuitProduct) {
      const product = buildProduct(circuitProduct)
      return product
    },

    /**
    * Construye los elementos de la lista de circuitos
    *
    * @summary Construye los elementos de la lista de circuitos a partir de la
    * información proporcionada por el servidor. Si el circuito ya existe en la lista
    * se actualiza, de lo contrario se agrega a la lista. Además, si tiene el precio de oferta
    * activo se agrega a la lista de circuitos con precio de oferta activo
    */
    buildCircuitsList() {
      const orderCircuits = this.refund.circuits
      this.circuits = orderCircuits

      this.circuits.forEach(circuit => {
        const orderCircuit = { ...circuit }

        const orderCircuitsProducts = []

        const uuidCircuit = uuidv4()

        circuit.products.forEach(product => {
          const formProduct = buildProduct(product)

          this.$set(formProduct, 'unitPrice', product.product.UnitPrice)
          this.$set(formProduct, 'unitPriceType', product.product.UnitPriceType)
          this.$set(formProduct, 'unitPriceOrigin', product.product.UnitPriceOrigin)
          this.$set(formProduct, 'uuidCircuit', uuidCircuit)

          orderCircuitsProducts.push({ ...formProduct })
        })

        orderCircuit.products = orderCircuitsProducts
        this.$set(orderCircuit, 'uuid', uuidCircuit)
        this.$set(orderCircuit, 'total', 0)

        const existsAtIndex = this.detailCircuits.findIndex(c => c.IdCircuit === orderCircuit.IdCircuit)

        if (existsAtIndex === -1) {
          this.addDetailCircuit(orderCircuit)
        } else {
          this.updateDetailCircuit(orderCircuit)
        }
      })
    },

    /**
    * Mensaje de alerta
    *
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Texto del mensaje
    * @param {string} variant - Tipo de mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
